import {useUserContext} from 'components/contexts/user';
import {Box} from 'components/mui';
import {NavMenuItem} from 'components/ui/menu/MenuItem';
import _ from 'lodash';
import {Roles} from 'src/types/graphql-types';

type NavigationProps = {
    activeNavItem: string;
    navItems: {
        id: string;
        href: string;
        label?: string;
        featureFlag?: string;
        tooltipPlacement?: string;
        isSuperUserOnly?: boolean;
        requiredRoles?: Partial<Roles>;
        onClick?: () => void;
    }[];
    navigationExpanded?: boolean;
};

const MobileNavigation = ({activeNavItem, navItems}: NavigationProps) => {
    const user = useUserContext();
    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                gap={2}
                flexWrap="wrap"
                justifyContent="center"
            >
                {navItems
                    .filter(
                        (navItem) =>
                            !navItem?.isSuperUserOnly ||
                            (navItem?.isSuperUserOnly && user?.isSuperuser) ||
                            _.isMatch(user.roles, navItem.requiredRoles)
                    )

                    .map((navItem) => {
                        return (
                            <Box key={navItem.id}>
                                <NavMenuItem
                                    menuItemId={navItem.id}
                                    href={navItem.href}
                                    activeNavItem={activeNavItem}
                                    onClick={navItem.onClick}
                                />
                            </Box>
                        );
                    })}
            </Box>
        </>
    );
};

export {MobileNavigation};

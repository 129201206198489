import styled from 'styled-components';
import PropTypes from 'prop-types';

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100vh;
`;

const StyledWrapper = styled.div`
    margin: 0 20%;
    text-align: center;
`;

const ErrorPage = ({pageId, title, children}) => {
    return (
        <PageWrapper>
            <StyledWrapper>{children}</StyledWrapper>
        </PageWrapper>
    );
};

ErrorPage.propTypes = {
    pageId: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.object.isRequired,
};

export {ErrorPage};

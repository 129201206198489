import {Link} from 'components/mui';
import {ErrorPage} from 'components/ui/page/ErrorPage';
import {Logo} from 'components/ui/graphics/Logo';
import styled from 'styled-components';
import _ from 'lodash';
import User from 'core/User';
import {Roles} from 'src/types/graphql-types';

const ErrorPageStyles = styled.div`
    .title {
        margin-bottom: 50px;
    }
    .description {
        margin-bottom: 50px;
    }
    .notifications-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        top: -205px;
    }
`;

interface RoleBasedRendererProps {
    user: User;
    requiredRoles: Roles;
    children?: JSX.Element;
}

const RoleBasedRenderer = ({
    user,
    requiredRoles,
    children,
}: RoleBasedRendererProps) => {
    const userRoles = user.roles;

    if (user.isSuperuser) return children;
    if (!requiredRoles) return children;

    const hasRequiredRole = _.isMatch(userRoles, requiredRoles);

    if (!hasRequiredRole) {
        return (
            <ErrorPage title="Login" pageId="login">
                <ErrorPageStyles>
                    <Logo />
                    <h1 className="title">403 - Insuficient permissions</h1>
                    <p>
                        You do not have sufficient permissions to access this
                        page.
                    </p>
                    <p>
                        <Link href="/">Return Home</Link> |{' '}
                    </p>
                </ErrorPageStyles>
            </ErrorPage>
        );
    }

    return children;
};

export {RoleBasedRenderer};

import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {UserContext} from 'components/contexts/user';
import {
    PageDimensionsContext,
    PageDimensionsProvider,
} from 'components/providers/PageDimensionsProvider';
import {AuthenticationRouter} from 'components/routers/AuthenticationRouter';
import DefaultDesktopLayout from 'components/ui/layouts/DefaultDesktopLayout';
import {DefaultMobileLayout} from 'components/ui/layouts/DefaultMobileLayout';
import HeadTitle from 'components/ui/page/HeadTitle';
import {ErrorBoundary} from 'components/ui//error-boundary';
import ExegraphicLensesProvider from 'components/providers/ExegraphicLensesProvider.tsx';
import {RoleBasedRenderer} from './util/RoleBaseRenderer';

const PrivatePage = ({
    title,
    pageId,
    children,
    mobileLayout,
    desktopLayout,
    contentStyles,
    requiredRoles,
}) => {
    const theme = useTheme();
    return (
        <>
            <HeadTitle>{title}</HeadTitle>
            <AuthenticationRouter>
                {({user}) => (
                    <RoleBasedRenderer
                        user={user}
                        requiredRoles={requiredRoles}
                    >
                        <UserContext.Provider value={user}>
                            <PageDimensionsProvider>
                                <PageDimensionsContext.Consumer>
                                    {(pageDimensions) => {
                                        if (
                                            pageDimensions.windowWidth &&
                                            pageDimensions.windowWidth <
                                                theme.mobileToDesktopBreakpoint
                                        ) {
                                            return (
                                                <DefaultMobileLayout
                                                    pageId={pageId}
                                                    title={title}
                                                >
                                                    <ErrorBoundary>
                                                        <ExegraphicLensesProvider>
                                                            {children}
                                                        </ExegraphicLensesProvider>
                                                    </ErrorBoundary>
                                                </DefaultMobileLayout>
                                            );
                                        }

                                        return (
                                            <DefaultDesktopLayout
                                                pageId={pageId}
                                                title={title}
                                                contentStyles={contentStyles}
                                            >
                                                <ErrorBoundary>
                                                    {children}
                                                </ErrorBoundary>
                                            </DefaultDesktopLayout>
                                        );
                                    }}
                                </PageDimensionsContext.Consumer>
                            </PageDimensionsProvider>
                        </UserContext.Provider>
                    </RoleBasedRenderer>
                )}
            </AuthenticationRouter>
        </>
    );
};

PrivatePage.propTypes = {
    pageId: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.any,
    mobileLayout: PropTypes.func,
    desktopLayout: PropTypes.func,
    contentStyles: PropTypes.object,
    requiredRoles: PropTypes.object,
};

export {PrivatePage};

import Image from 'next/legacy/image';
import Router from 'next/router';
import PropTypes from 'prop-types';
import {HOMEPAGE_URL} from 'components/util/routeConstants';
import styled from 'styled-components';

const LogoImage = styled(Image)`
    &.logo {
        cursor: default;
        &--link {
            cursor: pointer;
        }
    }
`;

const Logo = ({isLink = false}) => {
    const imageProps = {
        layout: 'fixed',
        src: '/rev-logo-basic.svg',
        height: '48',
        width: '166',
        alt: 'Rev logo',
    };

    if (isLink) {
        imageProps.onClick = () => Router.push(HOMEPAGE_URL);
    }

    return (
        <LogoImage
            className={`logo ${isLink && 'logo--link'}`}
            {...imageProps}
        />
    );
};

Logo.propTypes = {
    isLink: PropTypes.bool,
};

export {Logo};
